import * as React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  Menu,
  List,
  MenuItem,
  ListItem,
  ListItemText,
  useMediaQuery,
  Collapse,
  ButtonGroup,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  BsBuildingFillGear,
  BsPeopleFill,
  BsPiggyBankFill,
} from "react-icons/bs";
import { useTheme } from "@mui/material/styles";
import { MdOutlineBarChart } from "react-icons/md";
import { PiBankFill } from "react-icons/pi";
import { RiGovernmentFill } from "react-icons/ri";
import { FaGavel } from "react-icons/fa";

export const ButtonAppBar = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openAcessos, setOpenAcessos] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        (event.type === "keydown" &&
          (event as React.KeyboardEvent).key === "Tab") ||
        (event as React.KeyboardEvent).key === "Shift"
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const handleClickInicio = () => {
    const element = document.getElementById("inicio");
    element?.scrollIntoView({ behavior: "smooth" });
    setDrawerOpen(false);
  };

  const handleClickPlanos = () => {
    const element = document.getElementById("planos");
    element?.scrollIntoView({ behavior: "smooth" });
    setDrawerOpen(false);
  };

  const handleClickContato = () => {
    const element = document.getElementById("contato");
    element?.scrollIntoView({ behavior: "smooth" });
    setDrawerOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickSobre = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigate = (id: any) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`h-[60px] 2xl:h-[60px] overflow-hidden`}>
      <AppBar
        position="absolute"
        sx={{
          color: "darkgreen",
          height: isMobile ? "60px" : "60px",
          backgroundColor: "#F8F9FA",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            marginTop: isMobile ? "2px" : null,
            justifyContent: isMobile ? "space-between" : "space-around",
          }}
        >
          <div className="flex md:flex-col flex-row">
            {isMobile ? (
              <IconButton
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            ) : null}

            <img
              src="img/GLC 2(1).png"
              className="md:w-16 w-20 drop-shadow"
              alt=""
            />
          </div>

          <div>
            {!isMobile && (
              <div className="flex flex-row gap-x-10 text-orange-900">
                <Button
                  color="inherit"
                  sx={{
                    position: "relative",
                    "&:hover": {
                      backgroundColor: "transparent",
                      "&:after": {
                        content: "''",
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        height: "2px",
                        width: "100%",
                        backgroundColor: "orange",
                        animation: "borderGrowCenter 0.3s ease-out forwards",
                        transform: "translateX(-50%)",
                      },
                    },
                    "&:after": {
                      content: "''",
                      position: "absolute",
                      bottom: 0,
                      left: "50%",
                      height: "2px",
                      width: "0",
                      backgroundColor: "orange",
                      transition: "width 0.3s ease-out",
                      transform: "translateX(-50%)",
                    },
                  }}
                  onClick={handleClickInicio}
                >
                  Inicio
                </Button>

                <div>
                  <Button
                    color="inherit"
                    sx={{
                      position: "relative",
                      "&:hover": {
                        backgroundColor: "transparent",
                        "&:after": {
                          content: "''",
                          position: "absolute",
                          bottom: 0,
                          left: "50%",
                          height: "2px",
                          width: "100%",
                          backgroundColor: "orange",
                          animation: "borderGrowCenter 0.3s ease-out forwards",
                          transform: "translateX(-50%)",
                        },
                      },
                      "&:after": {
                        content: "''",
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        height: "2px",
                        width: "0",
                        backgroundColor: "orange",
                        transition: "width 0.3s ease-out",
                        transform: "translateX(-50%)",
                      },
                    }}
                    onClick={handleClickSobre}
                  >
                    Acessos
                  </Button>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{
                      "& .MuiMenuItem-root": {
                        paddingLeft: "20px",
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleNavigate("empresa")}>
                      <BsBuildingFillGear style={{ marginRight: "5px" }} />
                      <ListItemText primary="Empresa" />
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigate("acessoria")}>
                      <MdOutlineBarChart style={{ marginRight: "5px" }} />
                      <ListItemText primary="Assessoria" />
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigate("governo")}>
                      <RiGovernmentFill style={{ marginRight: "5px" }} />
                      <ListItemText primary="Governo" />
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleNavigate("banco")}
                    >
                      <PiBankFill style={{ marginRight: "5px" }} />
                      <ListItemText primary="Banco" />
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigate("colaborador")}>
                      <BsPeopleFill style={{ marginRight: "5px" }} />
                      <ListItemText primary="Colaborador" />
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigate("licitapramim")}>
                      <FaGavel style={{ marginRight: "5px" }} />
                      <ListItemText primary="Licitapramim" />
                    </MenuItem>
                  </Menu>
                </div>

                <Button
                  color="inherit"
                  sx={{
                    position: "relative",
                    "&:hover": {
                      backgroundColor: "transparent",
                      "&:after": {
                        content: "''",
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        height: "2px",
                        width: "100%",
                        backgroundColor: "orange",
                        animation: "borderGrowCenter 0.3s ease-out forwards",
                        transform: "translateX(-50%)",
                      },
                    },
                    "&:after": {
                      content: "''",
                      position: "absolute",
                      bottom: 0,
                      left: "50%",
                      height: "2px",
                      width: "0",
                      backgroundColor: "orange",
                      transition: "width 0.3s ease-out",
                      transform: "translateX(-50%)",
                    },
                  }}
                  onClick={handleClickPlanos}
                >
                  Planos
                </Button>

                <Button
                  color="inherit"
                  sx={{
                    position: "relative",
                    "&:hover": {
                      backgroundColor: "transparent",
                      "&:after": {
                        content: "''",
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        height: "2px",
                        width: "100%",
                        backgroundColor: "orange",
                        animation: "borderGrowCenter 0.3s ease-out forwards",
                        transform: "translateX(-50%)",
                      },
                    },
                    "&:after": {
                      content: "''",
                      position: "absolute",
                      bottom: 0,
                      left: "50%",
                      height: "2px",
                      width: "0",
                      backgroundColor: "orange",
                      transition: "width 0.3s ease-out",
                      transform: "translateX(-50%)",
                    },
                  }}
                  onClick={handleClickContato}
                >
                  FAQ
                </Button>

                <Button
                  color="inherit"
                  sx={{
                    position: "relative",
                    "&:hover": {
                      backgroundColor: "transparent",
                      "&:after": {
                        content: "''",
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        height: "2px",
                        width: "100%",
                        backgroundColor: "orange",
                        animation: "borderGrowCenter 0.3s ease-out forwards",
                        transform: "translateX(-50%)",
                      },
                    },
                    "&:after": {
                      content: "''",
                      position: "absolute",
                      bottom: 0,
                      left: "50%",
                      height: "2px",
                      width: "0",
                      backgroundColor: "orange",
                      transition: "width 0.3s ease-out",
                      transform: "translateX(-50%)",
                    },
                  }}
                  onClick={() => handleNavigate("footer")}
                >
                  CONTATO
                </Button>
              </div>
            )}
          </div>

          <ButtonGroup>
            <Button
              color="inherit"
              variant="outlined"
              sx={{
                width: "100px",
                borderRadius: "10px",
                borderColor: "#ffc107",
                borderWidth: "1px",
                color: "#ffc107",
                fontSize: "0.8rem",
                "&:hover": { backgroundColor: "#F68C0020" },
              }}
              onClick={() => (window.location.href = "https://app.portalglc.com")}
            >
              Login
            </Button>

            <Button
              color="inherit"
              variant="outlined"
              sx={{
                borderRadius: "10px",
                borderColor: "#ffc107",
                borderWidth: "1px",
                color: "#ffc107",
                fontSize: "0.8rem",
                "&:hover": { backgroundColor: "#F68C0020" },
              }}
              onClick={() => (window.location.href = "https://app.portalglc.com/registrar")}
            >
              Registro
            </Button>
          </ButtonGroup>
        </Toolbar>
      </AppBar>
      <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
        <List style={{ width: "200px" }}>
          <ListItem button onClick={handleClickInicio}>
            <ListItemText primary="Inicio" />
          </ListItem>

          <ListItem button onClick={() => setOpenAcessos(!openAcessos)}>
            <ListItemText primary="Acessos" />
          </ListItem>
          <Collapse in={openAcessos} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button onClick={() => handleNavigate("empresa")}>
                <BsBuildingFillGear style={{ marginRight: "10px" }} />
                <ListItemText primary="Empresa" />
              </ListItem>

              <ListItem button onClick={() => handleNavigate("acessoria")}>
                <MdOutlineBarChart style={{ marginRight: "10px" }} />
                <ListItemText primary="Assessoria" />
              </ListItem>

              <ListItem button onClick={() => handleNavigate("governo")}>
                <RiGovernmentFill style={{ marginRight: "10px" }} />
                <ListItemText primary="Governo" />
              </ListItem>

              <ListItem
                button
                onClick={() => handleNavigate("banco_investidor")}
              >
                <PiBankFill style={{ marginRight: "10px" }} />
                <ListItemText primary="Banco" />
              </ListItem>

              <ListItem button onClick={() => handleNavigate("colaborador")}>
                <BsPeopleFill style={{ marginRight: "10px" }} />
                <ListItemText primary="Colaborador" />
              </ListItem>

              <ListItem button onClick={() => handleNavigate("licitapramim")}>
                <FaGavel style={{ marginRight: "10px" }} />
                <ListItemText primary="Licitapramim" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={handleClickPlanos}>
            <ListItemText primary="Planos" />
          </ListItem>
          <ListItem button onClick={handleClickContato}>
            <ListItemText primary="Contato" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};
