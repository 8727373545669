import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FaFileContract, FaHandshake } from "react-icons/fa";
import { RiAuctionFill } from "react-icons/ri";

interface CounterProps {
    titulo: string;
    texto: string;
    valorFinal: number;
    duracao: number; // duração da animação em milissegundos
}

export const Counter: React.FC<CounterProps> = ({
    titulo,
    texto,
    valorFinal,
    duracao,
}) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let inicio = 0;
        const incremento = valorFinal / (duracao / 100);
        const intervalo = setInterval(() => {
            inicio += incremento;
            if (inicio >= valorFinal) {
                inicio = valorFinal;
                clearInterval(intervalo);
            }
            setCount(Math.floor(inicio));
        }, 100);

        return () => clearInterval(intervalo);
    }, [valorFinal, duracao]);

    return (
        <div className="counter flex flex-col items-center 2xl:px-10 2xl:w-64  px-8 w-52">
            <p className="2xl:text-3xl text-2xl font-bold text-yellow-500">
                {" "}
                {titulo === "Licitados" ? "R$" : ""}{" "}
                {`${count.toLocaleString("pt-BR")}`}{" "}
                {titulo === "Licitados" ? "Bi" : ""}
            </p>
            <h1 className="text-md 2xl:text-lg font-medium text-white">
                {titulo}
            </h1>
            <p className="text-md 2xl:text-lg text-white">{texto}</p>
        </div>
    );
};

export const GrupoContador = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
    });

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={inView ? "show" : "hidden"}
            className="relative z-10 flex-col gap-y-5  flex lg:flex-row md:justify-between 2xl:justify-around justify-center items-center my-8"
        >
            <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ transition: 2, delay: 1.8 }}
                className="flex flex-col justify-around border-[1px] 2xl:py-16 py-10 border-yellow-500"
            >
                <span className="flex flex-col justify-center items-center text-amber-500 text-4xl">
                    <FaFileContract />
                </span>
                <div className="">
                    <Counter
                        titulo="Licitados"
                        texto="neste ano"
                        valorFinal={50}
                        duracao={2500}
                    />
                </div>
            </motion.div>
            <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ transition: 2.5, delay: 1.9 }}
                className=" border-[1px] 2xl:py-16 py-10 border-yellow-500"
            >
                <span className="flex flex-col justify-center items-center  text-amber-500 text-4xl">
                    <RiAuctionFill />
                </span>
                <Counter
                    titulo="Pregões"
                    texto="neste ano"
                    valorFinal={30690}
                    duracao={3200}
                />
            </motion.div>
            <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ transition: 3, delay: 2 }}
                className="border-[1px] 2xl:py-16 py-10 border-yellow-500"
            >
                <span className="flex flex-col justify-center items-center text-amber-500 text-4xl">
                    <FaHandshake />
                </span>
                <Counter
                    titulo="Itens negociados"
                    texto="neste ano"
                    valorFinal={1317975}
                    duracao={4200}
                />
            </motion.div>
        </motion.div>
    );
};
