import React from "react";
import { useInView } from "react-intersection-observer";
import { animate, motion } from "framer-motion";

interface INovoCardProps {
    plano: string;
    precoAntigo: number;
    precoDesconto: number;
}

export const NovoCardPrecos: React.FC<INovoCardProps> = ({
    plano,
    precoAntigo,
    precoDesconto,
}) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
    });

    const variants = {
        hidden: { x: -100, opacity: 0 },
        show: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 0.8,
                staggerChildren: 0.001,
            },
        },
    };

    return (
        <div className="flex justify-center">
            <motion.div
                ref={ref}
                variants={variants}
                initial="hidden"
                animate={inView ? "show" : "hidden"}
                className="md:w-64 w-full h-80 py-12 px-3 flex flex-col justify-center items-center border-[1px] text-white border-yellow-500"
            >
                <h1 className="text-2xl font-bold text-center bg-yellow-500 w-full rounded-sm py-1 border-yellow-500 px-4 m-auto text-[#1a1a28]">
                    {plano}
                </h1>
                <p className="font-light">De</p>
                <div className="">
                    <motion.div
                        initial={{ width: "0px" }}
                        animate={inView ? { width: "auto" } : undefined}
                        transition={
                            inView
                                ? {
                                    ease: "easeIn",
                                    transition: 1,
                                    repeat: Infinity,
                                    repeatDelay: 1.4,
                                    repeatType: "loop",
                                }
                                : undefined
                        }
                        className="flex h-1 flex-col translate-y-[1.1rem] items-center bg-red-500 z-10"
                    />
                    <h2 className="text-xl font-semibold text-gray-400">
                        R$ {precoAntigo.toFixed(2).replace(".", ",")}
                    </h2>
                </div>
                <p className="font-light">por apenas</p>
                <h2 className="text-2xl text-amber-500 font-semibold">
                    R$ {precoDesconto.toFixed(2).replace(".", ",")}
                </h2>
                <a href="https://app.portalglc.com" className="z-20">
                    <motion.button
                        initial={{ scale: 1 }}
                        animate={{ scale: 1.15 }}
                        transition={{
                            repeat: Infinity,
                            repeatType: "mirror",
                            duration: 1,
                            delay: 0.8,
                        }}
                        type="button"
                        className="flex justify-center hover:scale-105 transition-all 2xl:text-lg rounded-lg py-1 m-auto px-3 2xl:px-5 2xl:py-1 border-[1px] font-semibold bg-yellow-500 border-yellow-500 text-[#1a1a28] mt-5"
                    >
                        Registre-se Agora!
                    </motion.button>
                </a>
            </motion.div>
        </div>
    );
};
