import { FC, useEffect, useState } from "react";
import { Particles, initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import type { IParticlesProps } from "../../../node_modules/@tsparticles/react/dist/IParticlesProps";

export const ParticlesComponent: FC<IParticlesProps> = (props) => {
    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    let options = {
        autoPlay: true,
        background: {
            color: {
                value: "transparent",
            },
        },
        clear: true,
        delay: 0,
        fullScreen: {
            enable: false,
            zIndex: -1,
        },
        fpsLimit: 120,
        interactivity: {
            events: {
                onClick: {
                    enable: false,
                    mode: "push",
                },
                onDiv: {
                    enable: false,
                    type: "circle" as "circle" | "rectangle" | undefined,
                },
                onHover: {
                    enable: true,
                    mode: "repulse",
                    parallax: {
                        enable: false,
                        force: 2,
                        smooth: 10,
                    },
                },
                resize: {
                    delay: 0.5,
                    enable: true,
                },
            },
            modes: {
                trail: {
                    delay: 1,
                    pauseOnStop: false,
                    quantity: 1,
                },
                attract: {
                    distance: 200,
                    duration: 0.4,
                    easing: "ease-out-quad",
                    factor: 1,
                    maxSpeed: 50,
                    speed: 1,
                },
                bounce: {
                    distance: 200,
                },
                bubble: {
                    distance: 200,
                    duration: 0.4,
                    mix: false,
                    divs: {
                        distance: 200,
                        duration: 0.4,
                        mix: false,
                    },
                },
                connect: {
                    distance: 80,
                    links: {
                        opacity: 0.5,
                    },
                    radius: 60,
                },
                grab: {
                    distance: 100,
                    links: {
                        blink: false,
                        consent: false,
                        opacity: 1,
                    },
                },
                push: {
                    default: true,
                    quantity: 4,
                },
                remove: {
                    quantity: 2,
                },
                repulse: {
                    distance: 200,
                    duration: 0.4,
                    factor: 100,
                    speed: 1,
                    maxSpeed: 50,
                    easing: "ease-out-quad",
                    divs: {
                        distance: 200,
                        duration: 0.4,
                        factor: 100,
                        speed: 1,
                        maxSpeed: 50,
                        easing: "ease-out-quad",
                    },
                },
                slow: {
                    factor: 3,
                    radius: 200,
                },
                light: {
                    area: {
                        gradient: {
                            start: {
                                value: "#ffffff",
                            },
                            stop: {
                                value: "#000000",
                            },
                        },
                        radius: 1000,
                    },
                    shadow: {
                        color: {
                            value: "#000000",
                        },
                        length: 2000,
                    },
                },
            },
        },
        particles: {
            bounce: {
                horizontal: {
                    value: 1,
                },
                vertical: {
                    value: 1,
                },
            },

            color: {
                value: "#ffffff",
                animation: {
                    h: {
                        count: 0,
                        enable: true,
                        speed: 20,
                        decay: 0,
                        delay: 0,
                        sync: true,
                        offset: 0,
                    },
                    s: {
                        count: 0,
                        enable: true,
                        speed: 1,
                        decay: 0,
                        delay: 0,
                        sync: true,
                        offset: 0,
                    },
                    l: {
                        count: 0,
                        enable: true,
                        speed: 1,
                        decay: 0,
                        delay: 0,
                        sync: true,
                        offset: 0,
                    },
                },
            },
            effect: {
                close: true,
                fill: true,
            },
            move: {
                angle: {
                    offset: 0,
                    value: 90,
                },
                attract: {
                    distance: 200,
                    enable: false,
                    rotate: {
                        x: 3000,
                        y: 3000,
                    },
                },
                center: {
                    x: 50,
                    y: 50,
                    mode: "percent" as "percent" | "precise" | undefined,
                    radius: 0,
                },
                decay: 0,
                direction: undefined,
                drift: 0,
                enable: true,
                gravity: {
                    acceleration: 9.81,
                    enable: false,
                    inverse: false,
                    maxSpeed: 50,
                },
                path: {
                    clamp: true,
                    delay: {
                        value: 0,
                    },
                    enable: false,
                },
            },
            number: {
                density: {
                    enable: true,
                    width: 1920,
                    height: 1080,
                },

                value: 220,
            },
            opacity: {
                value: 0.2,
                animation: {
                    count: 0,
                    enable: false,
                    speed: 2,
                    decay: 0,
                    delay: 0,
                    sync: false,
                    mode: "auto" as
                        | "auto"
                        | "random"
                        | "increase"
                        | "decrease"
                        | undefined,
                    startValue: "random" as
                        | "random"
                        | "max"
                        | "min"
                        | undefined,
                },
            },
            reduceDuplicates: true,
            shape: {
                close: true,
                fill: true,
                type: "circle",
            },
            size: {
                value: {
                    min: 2,
                    max: 4,
                },
            },
            stroke: {
                width: 0,
            },
            zIndex: {
                value: 0,
                opacityRate: 1,
                sizeRate: 1,
                velocityRate: 1,
            },
            destroy: {
                mode: "none",
                split: {
                    count: 1,
                    factor: {
                        value: 3,
                    },
                    rate: {
                        value: {
                            min: 4,
                            max: 9,
                        },
                    },
                    sizeOffset: true,
                },
            },

            rotate: {
                value: 0,
                animation: {
                    enable: false,
                    speed: 0,
                    decay: 0,
                    sync: false,
                },
                direction: "clockwise",
                path: false,
            },
            orbit: {
                animation: {
                    count: 0,
                    enable: false,
                    speed: 1,
                    decay: 0,
                    delay: 0,
                    sync: false,
                },
                enable: false,
                opacity: 1,
                rotation: {
                    value: 45,
                },
                width: 1,
            },
            links: {
                blink: false,
                color: {
                    value: "#ffffff",
                },
                consent: false,
                distance: 150,
                enable: true,
                frequency: 1,
                opacity: 0.2,
                shadow: {
                    blur: 5,
                    color: {
                        value: "#000",
                    },
                    enable: false,
                },
                triangles: {
                    enable: false,
                    frequency: 1,
                },
                width: 1,
                warp: false,
            },
        },
    };

    return <Particles id="tsparticles" options={options} />;
};
