import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

export const Entrada = () => {

	const { ref, inView } = useInView({
		triggerOnce: true,
	});

	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	const isFullHD = useMediaQuery({ query: "(min-width: 1536px)" });

	const variants = {
		hidden: { y: 100, opacity: 0 },
		show: {
			y: 0,
			opacity: 1,
			transition: {
				duration: 0.5,
				staggerChildren: 0.001,
			},
		},
	};


	return (
		<motion.div
			ref={ref}
			variants={variants}
			initial="hidden"
			animate={inView ? "show" : "hidden"}
			style={{ lineHeight: '1.2em', overflow: 'visible' }}
			className="flex flex-col items-center mt-16 2xl:mt-24 "
		>
			{isMobile ? (
				<div style={{ lineHeight: '1.2em', overflow: 'visible' }} className="grid lg:grid-cols-3 grid-cols-1 justify-center w-auto text-white text-4xl sm:text-5xl 2xl:text-5xl mb-2">
					<div className="flex justify-center items-center">
						<motion.img
							initial={{ x: 150 }}
							animate={{ x: 0 }}
							transition={{ duration: 0.5, delay: 1 }}
							className=" z-10 font-semibold h-12 mr-2"
							src="img/G.png"
						/>
						<motion.span
							initial={{ clipPath: "inset(0 0 0 100%)" }}
							animate={{ clipPath: "inset(0 0 0 0%)" }}
							transition={{ delay: 1, duration: 0.5 }}
							className="z-0 text-white font-semibold scale-110 ml-2"
						>
							estão em
						</motion.span>
					</div>
					<div className="flex justify-center items-center px-3">
						<motion.img
							initial={{ x: 170 }}
							animate={{ x: 18 }}
							transition={{ duration: 0.5, delay: 1 }}
							className=" z-10 font-semibold h-12 "
							src="img/L2.png"
						/>
						<motion.span
							initial={{ clipPath: "inset(0 0 0 100%)" }}
							animate={{ clipPath: "inset(0 0 0 0%)" }}
							transition={{ delay: 1, duration: 0.6 }}
							className="z-0 text-white font-semibold scale-110 ml-2"
						>
							icitações e
						</motion.span>
					</div>
					<div className="flex justify-center items-center">
						<motion.img
							initial={{ x: 150 }}
							animate={{ x: 0 }}
							transition={{ duration: 0.5, delay: 1 }}
							className="z-10 font-semibold h-12 mr-2"
							src="img/C.png"
						/>
						<motion.span
							initial={{ clipPath: "inset(0 0 0 100%)" }}
							animate={{ clipPath: "inset(0 0 0 0%)" }}
							transition={{ delay: 1, duration: 0.6 }}
							className="z-0 text-white font-semibold scale-110 ml-2"
						>
							ontratos
						</motion.span>
					</div>
				</div>
			) : isFullHD ? (
				<div className="grid lg:grid-cols-3 grid-cols-1 overflow-visible justify-center w-auto text-white text-4xl sm:text-5xl 2xl:text-6xl mb-2">
					<div className="flex justify-center">
						<motion.img
							initial={{ x: 460, y: 16 }}
							animate={{ x: 0, y: 16 }}
							transition={{ duration: 0.5, delay: 1 }}
							className=" z-10 font-semibold h-12 mr-2"
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
							src="img/G.png"
						/>
						<motion.p
							initial={{ clipPath: "inset(0 0 0 100%)" }}
							animate={{ clipPath: "inset(0 0 0 0%)" }}
							transition={{ delay: 1, duration: 0.5 }}
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
							className="z-0 text-white font-semibold overflow-visible scale-110 ml-2"
						>
							estão em
						</motion.p>
					</div>
					<div className="flex justify-center overflow-visible px-3">
						<motion.img
							initial={{ x: 170, y: 16 }}
							animate={{ x: 0, y: 16 }}
							transition={{ duration: 0.5, delay: 1 }}
							className=" z-10 font-semibold h-12"
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
							src="img/L2.png"
						/>
						<motion.p
							initial={{ clipPath: "inset(0 0 0 100%)" }}
							animate={{ clipPath: "inset(0 0 0 0%)" }}
							transition={{ delay: 1, duration: 0.6 }}
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
							className="text-white font-semibold scale-110"
						>
							icitações e
						</motion.p>
					</div>
					<div className="flex justify-center">
						<motion.img
							initial={{ x: -200, y: 16 }}
							animate={{ x: 0, y: 16 }}
							transition={{ duration: 0.5, delay: 1 }}
							className="z-10 font-semibold h-12 mr-2"
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
							src="img/C.png"
						/>
						<motion.p
							initial={{ clipPath: "inset(0 0 0 100%)" }}
							animate={{ clipPath: "inset(0 0 0 0%)" }}
							transition={{ delay: 1, duration: 0.6 }}
							className="z-0 text-white font-semibold scale-110 ml-2"
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
						>
							ontratos
						</motion.p>
					</div>
				</div>
			) : (
				<div className="grid lg:grid-cols-3 grid-cols-1 justify-center w-full text-white mb-2" style={{ fontSize: "2.8rem" }}>
					<div className="flex justify-end">
						<motion.img
							initial={{ x: 390 }}
							animate={{ x: 0 }}
							transition={{ duration: 0.5, delay: 1 }}
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
							className=" z-10 font-semibold h-12 mr-2"
							src="img/G.png"
						/>
						<motion.span
							initial={{ clipPath: "inset(0 0 0 100%)" }}
							animate={{ clipPath: "inset(0 0 0 0%)" }}
							transition={{ delay: 1, duration: 0.5 }}
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
							className="z-0 text-white font-semibold scale-110 ml-1"
						>
							estão em
						</motion.span>
					</div>
					<div className="flex justify-center">
						<motion.img
							initial={{ x: 180 }}
							animate={{ x: 0 }}
							transition={{ duration: 0.5, delay: 1 }}
							className=" z-10 font-semibold h-12"
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
							src="img/L2.png"
						/>
						<motion.span
							initial={{ clipPath: "inset(0 0 0 100%)" }}
							animate={{ clipPath: "inset(0 0 0 0%)" }}
							transition={{ delay: 1, duration: 0.6 }}
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
							className="z-0 text-white font-semibold scale-110 -ml-2"
						>
							icitações e
						</motion.span>
					</div>
					<div className="flex justify-start">
						<motion.img
							initial={{ x: -80 }}
							animate={{ x: 0 }}
							transition={{ duration: 0.5, delay: 1 }}
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
							className="z-10 font-semibold h-12"
							src="img/C.png"
						/>
						<motion.span
							initial={{ clipPath: "inset(0 0 0 100%)" }}
							animate={{ clipPath: "inset(0 0 0 0%)" }}
							transition={{ delay: 1, duration: 0.6 }}
							style={{ lineHeight: '1.2em', overflow: 'visible' }}
							className="z-0 text-white font-semibold scale-110 ml-2"
						>
							ontratos
						</motion.span>
					</div>
				</div>
			)}
			<motion.h1
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ transition: 0.5, delay: 1.3 }}
				className="text-white text-4xl sm:text-5xl 2xl:text-6xl mb-2"
			>
				É a <span className="">solução</span> que transforma a complexidade das
				licitações em uma experiência
				<span className=""> simples e eficiente.</span>
			</motion.h1>
		</motion.div>
	);
};
