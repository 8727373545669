import React from "react";
import { Box, Typography } from "@mui/material";

export const Footer2 = () => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "55px",
                bgcolor: "#045821",
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
            }}
        >
            <div>
                <small>
                    &copy; Copyright 2024, GLC | Gestão em Licitações e
                    Contratos
                </small>
            </div>
        </Box>
    );
};
