import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

interface CardProps {
    number: number;
    title: string;
    description: string;
    imagePath: string;
}

export const Card = ({ number, title, description, imagePath }: CardProps) => {

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const empresa = [
        "Gestão simplificada e controle total",
        "Agilidade na participação em licitações",
        "Gestão de contratos eficiente",
    ];

    const governo = [
        "Facilidade na gestão de licitações governamentais",
        "Gestão de cotações e adesão simplificada",
        "Ambiente seguro e confiável",
    ];

    const colaborador = [
        "Acesso fácil às informações essenciais",
        "Segurança e alinhamento estratégico",
        "Aumento da produtividade e eficiência",
    ];

    const acessoria = [
        "Consultoria especializada para diferentes áreas",
        "Acompanhamento de clientes de forma simplificada",
        "Recursos exclusivos para assessorias",
    ];

    const banco = [
        "Oportunidades de crédito para o setor público",
        "Monitoramento do crescimento empresarial",
        "Apoio à expansão do mercado público",
    ];

    const licitapramim = [
        "Equipe especializada para gerenciar licitações",
        "Reduza custos e aumente a eficiência",
        "Participação assertiva e segura",
    ];

    const getItems = () => {
        switch (number) {
            case 1: return empresa;
            case 2: return acessoria;
            case 3: return governo;
            case 4: return banco;
            case 5: return colaborador;
            default: return licitapramim;
        }
    }

    const isOdd = number % 2 !== 0;

    const variants = {
        hidden: { x: (isOdd ? -100 : 100), opacity: 0 },
        visible: {
            x: 0, opacity: 1, transition: { duration: 0.5 },
            staggerChildren: 0.001, ease: "easeInOut"
        },
    };

    return (
        <motion.div
            className="shadow-lg rounded-lg flex h-full flex-col my-6"
            style={{ height: '70vh' }}
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={variants}
        >
            <div className={`flex flex-col ${isOdd ? 'md:flex-row' : 'md:flex-row-reverse'} h-full`}>
                <div className="relative md:w-1/3 h-full">
                    {/* Borda ao redor da imagem */}
                    <motion.div
                        className="absolute z-50 border-amber-500 border"
                        style={{
                            transform: `${isOdd ? "translate(-20px, -30px)" : "translate(20px, -30px)"}`, // Mantém deslocamento relativo
                            width: 'calc(100%)',  // Baseado no contêiner da imagem
                            height: 'calc(100%)', // Baseado no contêiner da imagem
                        }}
                    ></motion.div>
                    {/* Imagem */}
                    <img
                        src={imagePath}
                        alt={title}
                        className="w-full h-full object-cover z-0"
                    />
                </div>
                <div className="flex flex-col text-white justify-center p-4 md:w-2/3 leading-normal h-full">
                    <h2 className="mb-2 2xl:text-[2.3rem] text-[2.1rem] font-bold tracking-tight">{title}</h2>
                    <motion.div
                        initial={{ width: "0px" }}
                        animate={inView ? { width: "128px" } : ""}
                        transition={inView ? { duration: 1, delay: 1 } : undefined}
                        className="h-1 rounded-xl my-3 bg-yellow-400"
                    ></motion.div>
                    <p className=" mb-3 text-[1rem] 2xl:text-[1.2rem] my-4">{description}</p>
                    <div>
                        {getItems().map((item, idx) => (
                            <div key={idx} className="flex items-center mb-2">
                                <MdOutlineKeyboardDoubleArrowRight className="text-3xl text-yellow-400 mr-4 mt-2" />
                                <p className="text-base md:text-lg 2xl:text-xl mt-2">{item}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </motion.div>
    );
};
