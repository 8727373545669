import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { PaginaInicial } from "../pages/PaginaInicial";

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PaginaInicial />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
};
