import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GiRadarSweep, GiFullFolder } from "react-icons/gi";
import { FaChartPie } from "react-icons/fa";
import { useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const CardsMelhorEscolha: React.FC = () => {
    const theme = useTheme();

    const { ref, inView } = useInView({
        triggerOnce: true,
    });

    const variants = {
        hidden: { y: 100, opacity: 0 },
        show: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                staggerChildren: 0.0001,
            },
        },
    };

    const cards = [
        {
            title: "Captação Automática de Licitações",
            text: "Automatize de forma eficiente a busca por oportunidades de licitação.",
        },
        {
            title: "Gestão de Documentos",
            text: "Organize e gerencie todos os documentos necessários de forma centralizada.",
        },
        {
            title: "Dashboard",
            text: "Visualize dados e métricas importantes em um painel intuitivo",
        },
    ];

    const settings = {
        infinite: true,
        slidesToShow: 3,
        autoplay: true,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
        dots: true,
        centerMode: false,
        arrows: false,
        centerPadding: "0",
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <motion.div
            ref={ref}
            variants={variants}
            initial="hidden"
            animate={inView ? "show" : "hidden"}
            className="w-full  py-6 overflow-visible"
        >
            <Slider {...settings} className="overflow-visible h-full">
                {cards.map((card, index) => (
                    <div
                        key={index}
                        className="flex flex-col justify-around h-full items-center overflow-visible"
                    >
                        <div className="flex flex-col  md:mx-5 text-left max-w-screen  h-[275px] p-6 border border-white hover:transition-transform rounded-lg shadow hover:shadow-xl transition-all overflow-visible">
                            <div className="flex text-lime-500 font-bold w-[56px] h-[56px] text-5xl">
                                {index === 0 && <GiRadarSweep />}
                                {index === 1 && <GiFullFolder />}
                                {index === 2 && <FaChartPie />}
                            </div>
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-lime-500">
                                {card.title}
                            </h5>
                            <p className="mb-3 font-semibold text-white">
                                {card.text}
                            </p>
                        </div>
                    </div>
                ))}
            </Slider>
        </motion.div>
    );
};
