import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const FAQ = () => {

    return (
        <div className="text-white w-full 2xl:px-80 md:px-24 lg:px-52 py-12 rounded-3xl">
            <div className="rounded-3xl mx-8">
                <Accordion sx={{ border: "1px solid white", boxShadow: "none", backgroundColor: "transparent", color: "white" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ borderBottom: "1px solid white", paddingY: "10px", fontSize: "1.2rem", fontWeight: "700", "&:hover": { color: "orange" }, "&:focus": { color: "orange" } }}
                    >
                        O que é o Portal GLC?
                    </AccordionSummary>
                    <AccordionDetails>
                        O Portal GLC é uma plataforma de licitações simples e segura que conecta empresas, assessores e o governo em um só lugar. Ele facilita a gestão e participação em processos licitatórios, proporcionando uma experiência eficiente e integrada.
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ border: "1px solid white", boxShadow: "none", backgroundColor: "transparent", color: "white" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        sx={{ borderBottom: "1px solid white", paddingY: "10px", fontSize: "1.2rem", fontWeight: "700", "&:hover": { color: "orange" }, "&:focus": { color: "orange" } }}
                    >
                        Quais são os benefícios de usar o Portal GLC?
                    </AccordionSummary>
                    <AccordionDetails>
                        O Portal GLC oferece diversas funcionalidades que simplificam o seu dia a dia no mercado de licitações. Entre os benefícios estão a centralização das informações, a automação de processos e a segurança na troca de dados, o que torna a gestão de licitações mais ágil e eficaz.
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ border: "1px solid white", boxShadow: "none", backgroundColor: "transparent", color: "white" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel3-header"
                        sx={{ borderBottom: "1px solid white", paddingY: "10px", fontSize: "1.2rem", fontWeight: "700", "&:hover": { color: "orange" }, "&:focus": { color: "orange" } }}
                    >
                        Como posso me cadastrar no Portal GLC?
                    </AccordionSummary>
                    <AccordionDetails>
                        Para se cadastrar no Portal GLC, acesse o link fornecido e complete o formulário com apenas 4 perguntas. O processo é rápido e fácil, permitindo que você comece a usar o portal em pouco tempo.
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ border: "1px solid white", boxShadow: "none", backgroundColor: "transparent", color: "white" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel3-header"
                        sx={{ borderBottom: "1px solid white", paddingY: "10px", fontSize: "1.2rem", fontWeight: "700", "&:hover": { color: "orange" }, "&:focus": { color: "orange" } }}
                    >
                        Quais são os requisitos para participar de licitações no Portal GLC?
                    </AccordionSummary>
                    <AccordionDetails>
                        Para participar de licitações no Portal GLC, você precisa manter seus documentos atualizados e seguir os passos estabelecidos nos processos de licitação. Certifique-se de cumprir todos os requisitos para garantir sua participação.
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ border: "1px solid white", boxShadow: "none", backgroundColor: "transparent", color: "white" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel3-header"
                        sx={{ borderBottom: "1px solid white", paddingY: "10px", fontSize: "1.2rem", fontWeight: "700", "&:hover": { color: "orange" }, "&:focus": { color: "orange" } }}
                    >
                        Quais as formas de pagamento aceitas?
                    </AccordionSummary>
                    <AccordionDetails>
                        O Portal GLC aceita as seguintes formas de pagamento: crédito, boleto e Pix. Escolha a opção que melhor atende às suas necessidades durante o processo de pagamento.
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ border: "1px solid white", boxShadow: "none", backgroundColor: "transparent", color: "white" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel3-header"
                        sx={{ borderBottom: "1px solid white", paddingY: "10px", fontSize: "1.2rem", fontWeight: "700", "&:hover": { color: "orange" }, "&:focus": { color: "orange" } }}
                    >
                        Ao fazer minha inscrição, como faço para receber meu acesso?
                    </AccordionSummary>
                    <AccordionDetails>
                        Após completar sua inscrição, seu acesso ao Portal GLC será fornecido mediante o login com seu CPF e a senha registrada durante o cadastro.
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ border: "1px solid white", boxShadow: "none", backgroundColor: "transparent", color: "white" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel3-header"
                        sx={{ borderBottom: "1px solid white", paddingY: "10px", fontSize: "1.2rem", fontWeight: "700", "&:hover": { color: "orange" }, "&:focus": { color: "orange" } }}
                    >
                        Como o Portal GLC protege meus dados?
                    </AccordionSummary>
                    <AccordionDetails>
                        O Portal GLC adota rigorosas medidas de segurança para proteger seus dados. Utilizamos criptografia avançada e protocolos de segurança para garantir que suas informações sejam mantidas seguras e confidenciais durante toda a sua experiência na plataforma.
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>

    );
}