import React from "react";
import {
    ButtonAppBar,
    Entrada,
    FAQ,
    Card,
    CardsMelhorEscolha,
    ParticlesComponent,
    GrupoContador,
    NovoCardPrecos,
    Footer,
    Footer2,
} from "../shared/index";
import { useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { Typography, Box } from "@mui/material";
import { SlCompass } from "react-icons/sl";

import "./../app.css";

export const PaginaInicial = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div className="bg-[#0f1e35] overflow-y-auto ">
            <div>
                <nav className="overflow-hidden">
                    <ButtonAppBar />
                </nav>
            </div>

            <div className={`overflow-hidden`} id="inicio">
                <img
                    src="./img/BackgroundColagem.png"
                    alt=""
                    className="z-0"
                    style={{ position: "absolute", width: "100%", height: "full", objectFit: "cover" }}
                />
                <section className="flex md:flex-col flex-col justify-center items-center min-h-[70vh]">
                    <div className="absolute z-10 h-full top-0 w-full">
                        <ParticlesComponent />
                    </div>
                    <div className=" flex flex-col justify-around items-center relative mx-auto 2xl:px-60 md:px-20 lg:px-16 xl:px-48">
                        <div className="flex flex-col text-center items-center z-10">
                            <Entrada />
                        </div>

                        <motion.a
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 1.6 }}
                            href="https://app.portalglc.com/registrar"
                            className="z-20"
                        >
                            <motion.button
                                initial={{ scale: 1 }}
                                animate={{ scale: 1.15 }}
                                transition={{
                                    repeat: Infinity,
                                    repeatType: "mirror",
                                    duration: 1,
                                    delay: 0.5,
                                }}
                                type="button"
                                className="flex justify-center hover:scale-105 transition-all 2xl:text-lg rounded-lg py-1 m-auto px-3 2xl:px-5 2xl:py-1 border-[1px] font-semibold bg-yellow-500 border-yellow-500 text-[#1a1a28] mt-5"
                            >
                                Registre-se Agora!
                            </motion.button>
                        </motion.a>
                        <motion.div className="w-full bg-transparent">
                            <GrupoContador />
                        </motion.div>
                    </div>
                </section>

                <section
                    className="py-24 lg:mb-0 2xl:px-80 md:px-24 px-6"
                    id="sobre-nos"
                >
                    <div className="relative z-10 mt-16">
                        <Box>
                            <Typography
                                variant="h5"
                                style={{ fontWeight: "600" }}
                                color="#31ff46"
                                align="center"
                            >
                                Serviço Especializado
                            </Typography>
                            <Typography
                                variant={isMobile ? "h4" : "h2"}
                                style={{ fontWeight: "900" }}
                                color="orange"
                                align="center"
                            >
                                Sua Solução em Vendas Para o Governo
                            </Typography>
                        </Box>

                        <div className="flex flex-col my-12">
                            <div id="empresa">
                                <Card
                                    number={1}
                                    title="Empresa Fornecedora"
                                    description="Gerencie sua empresa com facilidade e total controle de qualquer lugar. Com o GLC, você conta com funcionalidades automatizadas para registros, acompanhamentos e atualizações em tempo real, facilitando a participação em licitações e a gestão dos seus contratos."
                                    imagePath="img/1.png"
                                />
                            </div>
                            <div id="acessoria">
                                <Card
                                    number={2}
                                    title="Assessoria Empresarial"
                                    description="Para servidores públicos, o GLC disponibiliza uma interface voltada à gestão de licitações e contratos governamentais. Cadastre atas de registro de preços, solicite cotações de serviços e produtos, e simplifique o processo de adesão em um ambiente seguro e confiável."
                                    imagePath="img/acessoria.jpg"
                                />
                            </div>
                            <div id="governo">
                                <Card
                                    number={3}
                                    title="Governo Licitador"
                                    description="Para servidores públicos, o GLC disponibiliza uma interface voltada à gestão de licitações e contratos governamentais. Cadastre atas de registro de preços, solicite cotações de serviços e produtos, e simplifique o processo de adesão em um ambiente seguro e confiável."
                                    imagePath="img/governo.jpg"
                                />
                            </div>
                            <div id="banco">
                                <Card
                                    number={4}
                                    title="Banco Investidor"
                                    description="Se você é investidor e busca oportunidades de crédito para impulsionar empresas com atuação no mercado de vendas para órgãos públicos, o GLC oferece uma área dedicada para você. Aqui, é possível oferecer crédito e monitorar o crescimento dos negócios com segurança."
                                    imagePath="img/bancoinvestidor.jpg"
                                />
                            </div>

                            <div id="colaborador">
                                <Card
                                    number={5}
                                    title="Colaborador"
                                    description="Exclusivo para funcionários de empresas e assessorias cadastradas na plataforma, essa opção permite que colaboradores acessem as informações e ferramentas necessárias para realizar suas atividades com eficiência e segurança, alinhando-se aos objetivos estratégicos da empresa."
                                    imagePath="img/colaborador.jpg"
                                />
                            </div>

                            <div id="licitapramim">
                                <Card
                                    number={6}
                                    title="Licitapramim"
                                    description="A GLC também conta com uma equipe que participa das licitações para você. Com o Licitapra mim, você pode contar com uma equipe completa pelo custo de um único funcionário, garantindo participação assertiva em licitações, com a qualidade e segurança que o GLC oferece!"
                                    imagePath="img/licitapramim.jpg"
                                />
                            </div>
                        </div>


                    </div>
                </section>

                <section className="relative z-10 py-6  flex flex-col justify-center items-center w-full mb-32 overflow-visible lg:mb-0 2xl:px-80 md:px-24 lg:px-52 px-6">
                    <div
                        className={`flex flex-col justify-center overvlow-visible w-auto h-auto`}
                    >
                        <Typography
                            variant="h6"
                            fontWeight="600"
                            align="center"
                            color="lime"
                        >
                            Garantido
                        </Typography>
                        <Typography
                            variant={isMobile ? "h4" : "h3"}
                            color="orange"
                            align="center"
                            sx={{ fontWeight: "700" }}
                        >
                            Descubra por que somos a melhor escolha
                        </Typography>
                    </div>
                    <div className="flex m-auto justify-center items-center overflow-visible h-[50vh] mt-10 w-full">
                        <CardsMelhorEscolha />
                    </div>
                </section>

                <section
                    id="planos"
                    className=" h-full mt-20 2xl:px-80 lg:px-52 px-6"
                >
                    <div className="flex justify-center w-auto">
                        <div className="h-2 w-32 bg-transparent"></div>
                    </div>

                    <div>
                        <Typography
                            variant="h6"
                            color="#31ff46"
                            align="center"
                            fontWeight="700"
                        >
                            Soluções Personalizadas
                        </Typography>
                        <Typography
                            variant={isMobile ? "h3" : "h2"}
                            color="orange"
                            align="center"
                            fontWeight="700"
                        >
                            Planos de Contratação
                        </Typography>
                        <div className="mt-8 mb-12">
                            <Typography
                                variant={isMobile ? "body1" : "h5"}
                                color="white"
                                align="center"
                            >
                                Explore nossos Planos de Contratação para
                                simplificar sua gestão de licitações e
                                contratos. Escolha o plano ideal para sua
                                empresa e otimize seu processo de compras
                                públicas.
                            </Typography>
                        </div>
                    </div>

                    <section id="formulario-planos h-auto">
                        <div className="">
                            <div className="grid grid-cols-1 xl:flex xl:h-96 h-auto mt-20 items-center justify-center">
                                <div className="">
                                    <NovoCardPrecos
                                        plano="GLC Light"
                                        precoAntigo={706.0}
                                        precoDesconto={599.0}
                                    />
                                </div>
                                <div className="md:w-full w-auto h-auto justify-center align-center  flex flex-col text-white">
                                    <h1 className="font-semibold text-2xl text-center my-3">
                                        Benefícios
                                    </h1>
                                    <div className="grid md:grid-cols-2 grid-cols-1 md:gap-y-0 gap-y-9 text-sm h-full ">
                                        <ul className="md:ml-2 flex flex-col text-sm justify-around gap-y-8">
                                            <li className="flex items-center">
                                                <span className="text-amber-500 text-xl my-2 mr-2">
                                                    <SlCompass />
                                                </span>
                                                <div>
                                                    <span className="font-bold text-amber-500">
                                                        Organograma:
                                                    </span>{" "}
                                                    Organize os
                                                    usuários/colaboradores da
                                                    empresa de forma simples,
                                                    visual e hierárquica,
                                                    otimizando a gestão interna.
                                                </div>
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-amber-500 text-xl my-2 mr-2">
                                                    <SlCompass />
                                                </span>
                                                <div>
                                                    <span className="font-bold text-amber-500">
                                                        Assessores:
                                                    </span>{" "}
                                                    Controle os acessos e
                                                    permissões dentro do sistema
                                                    com o canal de assessores,
                                                    garantindo que as pessoas
                                                    certas tenham as permissões
                                                    adequadas.
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="md:ml-2 flex flex-col text-sm justify-around gap-y-8">
                                            <li className="flex items-center">
                                                <span className="text-amber-500 text-xl my-2 mr-2">
                                                    <SlCompass />
                                                </span>
                                                <div>
                                                    <span className="font-bold text-amber-500">
                                                        Licitações e Contratos:
                                                    </span>{" "}
                                                    Automatize a captação de
                                                    licitações integradas,
                                                    gerencie documentos,
                                                    cadastre portais de compras
                                                    e tenha controle total sobre
                                                    cada etapa dos processos,
                                                    desde a captação até o
                                                    encerramento do contrato,
                                                    com registro completo de
                                                    todos os arquivos.
                                                </div>
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-amber-500 text-xl my-2 mr-2">
                                                    <SlCompass />
                                                </span>
                                                <div>
                                                    <span className="font-bold text-amber-500">
                                                        RH Estratégico:
                                                    </span>{" "}
                                                    Realize o registro ilimitado
                                                    de colaboradores e usuários,
                                                    com a função de armazenar
                                                    todos os documentos
                                                    relacionados a cada membro
                                                    da equipe, de forma
                                                    centralizada e organizada.
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <section id="contato" className="">
                    <div className="flex justify-center w-auto">
                        <div className="mb-12 bg-transparent"></div>
                    </div>

                    <div className="relative my-20">
                        <div className=" mx-auto ">
                            <Typography
                                variant={isMobile ? "h3" : "h2"}
                                sx={{
                                    fontWeight: "600",
                                    paddingBottom: "3.5rem",
                                }}
                                color="white"
                                align="center"
                            >
                                Perguntas Frequentes
                            </Typography>
                            <FAQ />
                        </div>
                    </div>
                </section>
            </div>

            <section className="overflow-hidden" id="footer">
                <Footer />
                <Footer2 />
            </section>
        </div>
    );
};
