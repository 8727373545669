import { Link } from "react-router-dom"
import { Facebook, X, Instagram, Phone, Mail, WhatsApp } from '@mui/icons-material'

export const Footer = () => {
    return (
        <footer className="w-full">
            <div className="bg-[#1a1a28] text-white py-8 px-4">
                <div className="container mx-auto flex flex-col items-center space-y-6">
                    {/* Logo */}
                    <div className="text-2xl font-semibold">
                        <img src="img/GLC 2(1).png" className="w-32" alt="" />
                    </div>

                    {/* Social Media Icons */}
                    <div className="flex space-x-4">
                        <Link
                            to="#"
                            className="bg-white p-2 rounded-full hover:bg-white/90 transition-colors"
                        >
                            <Facebook className="w-5 h-5 text-green-800" />
                            <span className="sr-only">Facebook</span>
                        </Link>
                        <Link
                            to="#"
                            className="bg-white p-2 rounded-full hover:bg-white/90 transition-colors"
                        >
                            <X className="w-5 h-5 text-green-800" />
                            <span className="sr-only">X</span>
                        </Link>
                        <Link
                            to="https://instagram.com/portalglc"
                            className="bg-white p-2 rounded-full hover:bg-white/90 transition-colors"
                        >
                            <Instagram className="w-5 h-5 text-green-800" />
                            <span className="sr-only">Instagram</span>
                        </Link>
                    </div>

                    {/* Address */}
                    <p className="text-center text-sm">
                        HORARIO DE ATENDIMENTO: DE SEG. Á SEX. DAS 08H ÀS 18H
                    </p>

                    {/* Contact Info */}
                    <div className="flex items-center my-6 space-x-6">
                        <Link to="https://wa.me/5562995642949" className="flex items-center hover:text-white/90">
                            <WhatsApp className="w-4 h-4 mr-2" />
                            <span>(62) 99564-2949</span>
                        </Link>
                        <Link to="mailto:info@gmail.com" className="flex items-center hover:text-white/90">
                            <Mail className="w-4 h-4 mr-2" />
                            <span>ATENDIMENTO@COMPRASGLC.COM</span>
                        </Link>
                    </div>

                </div>
            </div>
        </footer>
    )
}