import { AppRoutes } from "./routes/Routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const App = () => {
    const theme = createTheme({
        palette: {
            primary: {
                main: "#357a38",
            },
            secondary: {
                main: "#ff9100",
            },
        },
    });

    theme.typography.h3 = {
        fontSize: "2.1rem",
        fontWeight: "800",
        "@media (max-width:600px)": {
            fontSize: "1.8rem",
        },
        [theme.breakpoints.up("xl")]: {
            fontSize: "2.8rem",
        },
    };

    theme.typography.body1 = {
        fontSize: "1rem",
        "@media (max-width:600px)": {
            fontSize: "1rem",
        },
        [theme.breakpoints.up("xl")]: {
            fontSize: "1.2rem",
        },
    };

    return (
        <div className="">
            <ThemeProvider theme={theme}>
                <AppRoutes />
            </ThemeProvider>
        </div>
    );
};
